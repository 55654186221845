<template>
  <v-main>
    <v-container class="fill-height" fluid>
      <v-row align="center" justify="center">
        <v-col cols="12" sm="8" md="4">
          <v-card class="text-center">
            <v-progress-linear indeterminate></v-progress-linear>
            <v-card-text
              >Hang tight while the minions do their work! 🤓</v-card-text
            >
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import * as Utils from "@/utils/index.js";

export default {
  props: {
    to: Object,
  },

  methods: {
    loadApplication: function (isLoggedIn) {
      let promises = [this.$store.dispatch("loadApplication")];

      if (isLoggedIn === true) {
        promises.push(this.$store.dispatch("reloadUserSetup"));
      }

      Promise.all(promises)
        .then(() => {
          this.setTitle();
          this.setBugherd();
          if (typeof this.to.path !== "undefined") {
            // Check if it's an integration response
            if (this.to.matched.some((record) => record.meta.integration)) {
              Utils.processIntegration(this.to);
            } else {
              this.$router.push({ path: this.to.path });
            }
          } else {
            this.$router.push({ name: "index" });
          }
        })
        .catch(() => {
          this.$router.push({ name: "login" });
        });
    },
  },

  mounted() {
    this.loadApplication(this.$store.getters.isLoggedIn);
  },
};
</script>
